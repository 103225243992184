<template>
  <div id="PassageDefault" class="col-xs-12 col-sm-6 col-md-6 cover">
    <div class="intro_screen text cover">
      <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
      <p id="passage" :class="{ mock: mode == '1' }"></p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "PassageDefault",
  props: ["question_detail", "mode", "insertTitle"],
  watch: {
    question_detail() {
      this.setPassage();
    },
    insertTitle(val) {
      $(".insert-option").remove();
      $(".insert-area[data-answer='" + val + "'] .text-success").append(
        " <span class='insert-option'>" +
          this.question_detail.content.click_option +
          "</span>"
      );
      this.setAnswer(
        $(this)
          .parent()
          .data("answer")
      );
    }
  },
  mounted() {
    this.setPassage();
  },
  data() {
    return {};
  },
  methods: {
    setPassage() {
      let passage = document.getElementById("passage");
      passage.innerHTML = this.question_detail.content.html;
      this.scrollToParagraph();
      if (this.question_detail.type === "click") {
        let dom = document.getElementsByClassName("insert-area");
        for (var i = 0; i < dom.length; i++) {
          if(this.mode === "1"){
            dom[i].innerHTML = " <b class='text-success'><span style='font-size:28px;line-height: 14px'>■</span></b> ";
          } else {
            dom[i].innerHTML = ` <b class="text-success">[${dom[i].getAttribute(
              "data-answer"
            )}]  <span style="font-size:28px;line-height: 14px">■</span></b> `;

          }
         
        }
      }
      let vm = this; 
      $(".insert-area .text-success").click(function() {
        $(".insert-option").remove();
        $(this).append(
          " <span class='insert-option'>" +
            vm.question_detail.content.click_option +
            "</span>"
        );
        vm.setAnswer(
          $(this)
            .parent()
            .data("answer")
        );
      });
      $(
        ".insert-area[data-answer='" + this.insertTitle + "'] .text-success"
      ).append(
        ` <span class='insert-option'>
        ${this.question_detail.content.click_option}
        </span>`
      );
      this.setAnswer(
        $(this)
          .parent()
          .data("answer")
      );
    },
    //段落跳转
    scrollToParagraph() {
      let paragraph = document.getElementsByClassName("paragraph-anchor")[0];
      let text = document.getElementsByClassName("text")[0];
      if (paragraph !== undefined) {
        text.scrollTo(0, paragraph.offsetTop);
      }
    },
    setAnswer(answer) {
      this.$emit("insertAnswer", answer);
    }
  }
};
</script>

<style>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
}
.paragraph-anchor b {
  color: #28a745;
}
.paragraph>b:first-child {
  display: inline-block;
  margin-right: 5px;
}
.insert-area .text-success {
  cursor: pointer;
}
</style>
