<template>
  <div>
    <div class="intro_screen cover">
      <div class="col-md-12">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>
        <div class="init_title text">
          <p class="title-p">
            {{ question_detail.question }}
          </p>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h5 class="text-center">
              {{ question_detail.note.double[0] }} [3]
            </h5>
            <draggable
              class="list-group row"
              :list="myAnswers"
              group="people"
              @change="getAnswer()"
            >
              <div
                class="list-item"
                v-for="element in myAnswers"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
          <div class="col-sm-6">
            <h5 class="text-center">
              {{ question_detail.note.double[1] }} [2]
            </h5>
            <draggable
              class="list-group row"
              :list="Answers"
              group="people"
              @change="getRAnswer()"
            >
              <div
                class="list-item"
                v-for="element in Answers"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
          <div class="col-sm-12">
            <h5 class="text-center">Answer Choices</h5>
            <draggable class="list-group row" :list="options" group="people">
              <div
                class="list-item col-sm-6"
                v-for="element in options"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  components: {
    draggable
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: [],
      Answers: []
    };
  },
  mounted() {
    this.getAnswer();
    this.getRAnswer();
  },
  watch: {
    answers() {
      let options = [];
      let myAnswers = [];
      let Answers = [];
      if (this.answers.length > 1) {
        this.question_detail.options.forEach(option => {
          let lIndex = this.answers[0].indexOf(option.id);
          let rIndex = this.answers[1].indexOf(option.id);
          if (rIndex > -1 || lIndex > -1) {
            if (lIndex > -1) {
              myAnswers.push(option);
            } else {
              Answers.push(option);
            }
          } else {
            options.push(option);
          }
        });
        this.options = options;
        this.myAnswers = myAnswers;
        this.Answers = Answers;
      }
      this.getAnswer();
      this.getRAnswer();
    }
  },
  methods: {
    // setAnswers() {
    //   console.log(this.answers);
    // },
    getAnswer(max = 3) {
      if (this.myAnswers.length > max) {
        let item = this.myAnswers.pop();
        this.options.push(item);
      }
      let answers = [[], []];
      this.myAnswers.forEach(option => {
        answers[0].push(option.id);
      });
      this.Answers.forEach(option => {
        answers[1].push(option.id);
      });
      answers[0].sort(this.cmp);
      answers[1].sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    getRAnswer(max = 2) {
      if (this.Answers.length > max) {
        let item = this.Answers.pop();
        this.options.push(item);
      }
      let answers = [[], []];
      this.myAnswers.forEach(option => {
        answers[0].push(option.id);
      });
      this.Answers.forEach(option => {
        answers[1].push(option.id);
      });
      answers[0].sort(this.cmp);
      answers[1].sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b) {
      return a - b;
    }
  }
};
</script>

<style scoped>
.list-group {
  width: 100%;
  min-height: 300px;
  /* max-height: 400px; */
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
}
.list-item {
  height: 100px;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px;
}
</style>
