<template>
  <div :class="mode == '1' ? 'mylogo' : 'header-default'">
    <div class="col-sm-2">
      <a class="navbar-brand" href="/">
        <img
          v-if="mode == '1'"
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
          class="logo logo_image"
        />
        <img
          v-else
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
          class="logo logo_image"
        />
      </a>
    </div>
    <div class="col-sm-3">
      <b class="test_name">
        Writing (Task {{ question_detail.order }})
      </b>
    </div>
    <div class="col-sm-2">
      <el-button type="danger" title="Show / Hide">
        <span class="time_show">
          <strong id="minute_show">{{ time.minute }}</strong>
          <strong>:</strong>
          <strong id="second_show">{{ time.second }}</strong>
        </span>
        <span class="time_hide" style="display:none;">
          Show Time
        </span>
      </el-button>
    </div>
    <div class="col-sm-3">
      <div class="words text-center">
        Word Count:
        <b style="color: red">
          {{ count }}
        </b>
      </div>
    </div>
    <div class="col-sm-2">
      <el-button type="primary" @click="$emit('dook', 'next')">
        Submit
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question_detail", "answers", "seconds", "count", "mode"],
  watch: {
    question_detail() {
      this.countDown(this.seconds);
    }
  },
  mounted() {
    this.countDown(this.seconds);
  },
  methods: {
    countDown(seconds_remaining) {
      let vm = this;
      let minute = Math.floor(seconds_remaining / 60);
      let second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      vm.time.minute = minute;
      vm.time.second = second;
      setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;
        vm.time.minute = minute;
        vm.time.second = second;
        seconds_remaining--;
        if (seconds_remaining == 0) {
          // location.href = "listening.php?test_id=" + test_id + "&id=" + id;
        }
      }, 1000);
    }
  },
  data() {
    return {
      time: {
        minute: "",
        second: ""
      }
    };
  }
};
</script>

<style scoped>
.your_ansers {
  line-height: 80px;
}
</style>
