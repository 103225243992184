<template>
  <div>
    <reading
      v-if="this.question_type === 'reading'"
      :questions="questions"
      :question_detail="questions[question_index]"
      :allAnswers="user_exam.exam_answers"
      :answers="answers"
      :mode="mode"
      @setAnswers="setAnswers"
    ></reading>
    <listening
      v-if="this.question_type === 'listening'"
      :question_detail="questions[question_index]"
      :answers="answers"
      :mode="mode"
      @setAnswers="setAnswers"
    ></listening>
    <speaking
      v-if="this.question_type === 'speaking'"
      :question_detail="questions[question_index]"
      :questions="questions"
      :mode="mode"
      @setAnswers="setAnswers"
    ></speaking>
    <writing
      v-if="this.question_type == 'writing'"
      :question_detail="questions[question_index]"
      :questions="questions"
      :mode="mode"
      @setAnswers="setAnswers"
    ></writing>
    <div v-if="this.question_type == 'finished'"></div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Reading from "@/views/toefl/toeflTest/reading/Index";
import Listening from "@/views/toefl/toeflTest/listening/Index";
import Speaking from "@/views/toefl/toeflTest/speaking/Index";
import Writing from "@/views/toefl/toeflTest/writing/Index";
import TOEFL from "@/apis/toefl";
//子組件有需要，需要全局引入
import "@/views/toefl/toeflTest/style/tpo.css";

export default {
  metaInfo() {
    return {
      title: "TOEFL - TOEFL Mocks"
    };
  },

  components: {
    Reading,
    Listening,
    Speaking,
    Writing
  },

  mixins: [],

  props: [],
  data() {
    return {
      user_exam_id: 0,
      questions: [],
      question_index: 0,
      question_type: "",
      user_exam: {},
      answers: [],
      yourAnswers: [],
      isOld: false,
      question: 0,
      reading_status: null,
      listening_status: null,
      speaking_status: null,
      writing_status: null
    };
  },
  computed: {
    examId() {
      return this.$route.query.id;
    },
    userExamId() {
      return this.$route.query.user_exam_id;
    },
    mode() {
      return this.$route.query.mode;
    },
    is_finished() {
      if (this.question_index == this.questions.length - 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    isOld(value) {
      if (value) {
        this.open();
      }
    }
  },
  async mounted() {
    window.οnbefοreunlοad = function() {
      this.stopLeave();
    };
    if (this.userExamId > 0) {
      const res = await TOEFL.getQuestions(this.examId, {
        is_create: 0,
        other_status: [
          {
            mode: this.mode
          }
        ]
      });
      this.questions = res.questions;
      this.user_exam_id = this.userExamId;
      const answer_new = await TOEFL.getAnswers(this.user_exam_id);
      this.question = answer_new.user_exam.progress_question_id;
      this.getQuestionInfo();
    } else {
      const res = await TOEFL.getQuestions(this.examId, {
        is_create: 0,
        other_status: [
          {
            mode: this.mode
          }
        ]
      });
      this.questions = res.questions;
      this.user_exam_id = res.user_exam_id;
      //获取答案API
      const answer_new = await TOEFL.getAnswers(res.user_exam_id);
      //有答案说明是做过的
      if (answer_new.user_exam.exam_answers.length > 0) {
        this.question = answer_new.user_exam.progress_question_id;
        this.open();
      } else {
        this.question = this.questions[0].exam_question_id;
        this.getQuestionInfo();
      }
    }
  },

  methods: {
    async getNewTest() {
      const res = await TOEFL.getQuestions(this.examId, {
        is_create: 1,
        other_status: [
          {
            mode: this.mode
          }
        ]
      });
      this.user_exam_id = res.user_exam_id;
      this.question = this.questions[0].exam_question_id;
      this.getQuestionInfo();
    },
    //阻止跳转
    stopLeave() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    //是否继续？
    open() {
      this.$confirm("檢測到已經考過，是否繼續考試？", "繼續考試", {
        distinguishCancelAndClose: true,
        confirmButtonText: "繼續考試",
        cancelButtonText: "返回"
      })
        .then(() => {
          this.getQuestionInfo();
        })
        .catch(() => {
          this.$router.go(-1);
          // this.getNewTest();
        });
    },
    getQuestionInfo() {
      this.getQuestionIndex();
      this.getAnswers();
      this.question_type = this.questions[this.question_index].skill_name;
    },
    getQuestionIndex() {
      for (let index in this.questions) {
        if (
          parseInt(this.question) === this.questions[index].exam_question_id
        ) {
          this.question_index = parseInt(index);
        }
      }
    },
    async getQuestion(value, progress_remaining) {
      //提交答案
      let user_exam_id = this.user_exam_id;
      let answers = this.yourAnswers;
      let exam_question_id = this.questions[this.question_index]
        .exam_question_id;
      let is_finished = this.is_finished;
      //记录每个单元是否完成的状态
      if (answers.length > 0) {
        if (this.question_index < this.questions.length - 1) {
          if (
            this.questions[this.question_index + 1].skill_name !==
            this.questions[this.question_index].skill_name
          ) {
            if (
              this.questions[this.question_index].skill_name === "speaking" ||
              this.questions[this.question_index].skill_name === "writing"
            ) {
              this[this.questions[this.question_index].skill_name + "_status"] =
                "SCORING";
            } else {
              this[this.questions[this.question_index].skill_name + "_status"] =
                "DONE";
            }
          } else {
            this[this.questions[this.question_index].skill_name + "_status"] =
              "PROCESSING";
          }
        } else if (this.question_index === this.questions.length - 1) {
          if (
            this.questions[this.question_index].skill_name === "reading" ||
            this.questions[this.question_index].skill_name === "listening"
          ) {
            this[this.questions[this.question_index].skill_name + "_status"] =
              "DONE";
          } else {
            this[this.questions[this.question_index].skill_name + "_status"] =
              "SCORING";
          }
        }
        await TOEFL.updateAnswers(user_exam_id, {
          exam_question_id: exam_question_id,
          progress_remaining: progress_remaining,
          answers: answers,
          is_finished: is_finished,
          reading_status: this.reading_status,
          listening_status: this.listening_status,
          speaking_status: this.speaking_status,
          writing_status: this.writing_status
        });
      }
      //判断前进还是后退
      if (value == "back") {
        this.question_index--;
        if (this.question_index < 0) {
          this.question_index = 0;
        }
      } else {
        if (!this.is_finished) {
          this.question_index++;
        } else {
          this.toTranscript();
        }
      }
      this.question_type = this.questions[this.question_index].skill_name;
      //获取已经作答的答案
      this.getAnswers();
    },
    async skipQuestion(index) {
      console.log(index);
      //提交答案
      let user_exam_id = this.user_exam_id;
      let answers = this.yourAnswers;
      let exam_question_id = this.questions[this.question_index]
        .exam_question_id;
      this.question_index = index;
      this.question_type = this.questions[this.question_index].skill_name;
      //获取已经作答的答案
      this.getAnswers();
    },
    async getAnswers() {
      const user_exam = await TOEFL.getAnswers(this.user_exam_id);
      this.user_exam = user_exam.user_exam;
      this.answers = [];
      for (let i in this.user_exam.exam_answers) {
        if (
          this.user_exam.exam_answers[i].exam_question_id ===
          this.questions[this.question_index].exam_question_id
        ) {
          this.answers = this.user_exam.exam_answers[i].answers;
        }
      }
    },
    setAnswers(answers) {
      if (answers[0] == null) {
        answers = [];
      }
      this.yourAnswers = answers;
    },
    selectQuestion(question_index) {
      this.question_index = question_index;
    },
    toTranscript() {
      this.routerPush({
        name: "Transcript",
        query: {
          id: this.user_exam.id
        }
      });
    }
  }
};
</script>
