<template>
  <div>
    <div>
      <div v-if="question_detail.type === 'default'">
        <div v-if="page == 1">
          <question-default
            :mode="mode"
            :question_detail="question_detail"
            prepare_time="15"
            answer_time="45"
            :key="question_detail.chapter_order"
          ></question-default>
        </div>
      </div>
      <div v-if="question_detail.type === 'article_audio'">
        <article-passage
          v-if="page == 1"
          :mode="mode"
          :key="question_detail.chapter_order"
          :question_detail="question_detail"
        ></article-passage>
        <audio-passage
          v-if="page == 2"
          :mode="mode"
          :key="question_detail.chapter_order"
          :question_detail="question_detail"
        ></audio-passage>
        <div v-if="page == 3">
          <question-default
            :mode="mode"
            :question_detail="question_detail"
            prepare_time="30"
            answer_time="60"
            :key="question_detail.chapter_order"
          ></question-default>
        </div>
      </div>
      <div v-if="question_detail.type === 'audio'">
        <audio-passage
          v-if="page == 1"
          :mode="mode"
          :key="question_detail.chapter_order"
          :question_detail="question_detail"
        ></audio-passage>
        <div v-if="page == 2">
          <question-default
            :mode="mode"
            :question_detail="question_detail"
            prepare_time="20"
            answer_time="60"
            :key="question_detail.chapter_order"
          ></question-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import HZRecorder from "@/views/toefl/utils/recorder.js";
import AudioPassage from "./components/AudioPassage";
import ArticlePassage from "./components/ArticlePassage";
import QuestionDefault from "./components/QuestionDefault";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Speaking - Ivy-Way Academy"
    };
  },

  components: {
    QuestionDefault,
    ArticlePassage,
    AudioPassage
  },

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      page: 1,
      isComplete: false,
      isVoice: false,
      audio_src: {},
      recorder: {}
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.resetAudio();
  },

  methods: {
    hiddenPassage() {
      this.showQuestion = true;
    },
    getQuestion(value) {
      this.page = 1;
      this.resetAudio();
      //上传录音
      this.readyOriginal();
      //改变录音状态
      this.isVoice = false;
      this.$parent.getQuestion(value, 0);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    //重置录音
    resetAudio() {
      let vm = this;
      this.$nextTick(() => {
        try {
          window.AudioContext =
            window.AudioContext || window.webkitAudioContext;
          navigator.getUserMedia =
            navigator.getUserMedia || navigator.webkitGetUserMedia;
          window.URL = window.URL || window.webkitURL;
        } catch (e) {
          alert("No web audio support in this browser!");
        }
        navigator.getUserMedia(
          { audio: true },
          function(stream) {
            vm.recorder = new HZRecorder(stream);
          },
          function() {
            alert("No web audio support in this browser!");
          }
        );
      });
    },
    //录音开始和结束
    readyOriginal() {
      // let recorder = this.recorder;
      if (!this.isVoice) {
        this.recorder && this.recorder.start();
        this.isVoice = true;
        this.isComplete = false;
      } else {
        this.isVoice = false;
        this.recorder && this.recorder.stop();
        this.isComplete = true;
        this.audio_src = window.URL.createObjectURL(this.recorder.getBlob());
        let myBlob = this.recorder.getBlob();
        this.uploadAudio(myBlob);
      }
    },

    //播放录音
    playAudio() {
      let audio = document.getElementById("audio");
      audio.play();
      this.resetAudio();
    },
    setPage() {
      this.page++;
    },
    async uploadAudio(document) {
      const formData = new FormData();
      formData.append("type", "toefl_listening");
      formData.append("document", document, "record.wav");
      const res = await TOEFL.uploadAudio(formData);
      let answers = [];
      answers.push(res.document.url);
      this.$emit("setAnswers", answers);
    }
  }
};
</script>

<style scoped></style>
