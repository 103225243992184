<template>
  <div style="width: 100%;height: 100%">
    <div :class="mode == '1' ? 'mylogo' : 'header-default'">
      <div class="col-sm-4">
        <a class="navbar-brand" href="/">
          <img
            v-if="mode == '1'"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
            class="logo logo_image"
          />
          <img
            v-else
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-4">
        <b class="test_name">
          Writing Task ({{ question_detail.order }})
        </b>
      </div>
      <div class="col-sm-2">
        <el-button @click="play" type="primary" v-if="showPlay">
          Play
        </el-button>
      </div>
      <div class="col-sm-2">
        <el-button @click="setPage" type="primary">
          Start
        </el-button>
      </div>
    </div>

    <div class="listening-passage row mar">
      <div class="intro_screen cover text-center">
        <img class="style_change" :src="imgUrl" />
        <audio
          id="listening_passage"
          :src="question_detail.content.audio"
        ></audio>
        <h4>語音結束後，請按START按鈕進行答題。</h4>
        <div class="time-container col-md-6 col-md-offset-3">
          <div id="bar" style="width:0%;"></div>
        </div>
        <p class="col-md-12" id="total" style="color: #ccc"></p>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      timer: {}
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    play() {
      this.showPlay = false;
      let passage_audio = document.getElementById("listening_passage");
      passage_audio.load();
      let vm = this;
      passage_audio.oncanplay = function() {
        passage_audio.play();
        vm.run(Math.round(passage_audio.duration));
      };
    },
    setPage() {
      // clearInterval(this.timer);
      this.$parent.setPage();
    },
    countDown(seconds) {
      let minute;
      let second;
      minute = Math.floor(seconds / 60);
      second = Math.floor(seconds) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    run(audio_time) {
      let vm = this;
      let timer;
      let bar = document.getElementById("bar");
      let total = document.getElementById("total");
      let width = 0;
      let time = vm.countDown(audio_time);
      let second = 0;
      let surplus_time;
      vm.timer = window.setInterval(function() {
        second++;
        surplus_time = vm.countDown(second);
        width += 100 / audio_time;
        bar.style.width = width + "%";
        total.innerHTML = surplus_time + "/" + time;
        if (second >= audio_time) {
          bar.style.width = "100%";
          clearInterval(timer);
          return true;
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>
.intro_screen {
  width: 100%;
}
.mylogo {
  line-height: 80px;
}
.time-container {
  margin: 0 auto;
}
</style>
