<template>
  <div>
    <div v-if="mode == '1'" class="mylogo header-strict">
      <div class="col-sm-4">
        <a class="navbar-brand" href="/">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-4">
        Passage <b>{{ question_detail.chapter_order }}</b
        >, Question <b>{{ question_detail.question_order }} </b>
      </div>
      <div class="col-sm-4 control">
        <div class="control-button">
          <div class="review">
            <button type="primary" @click="$emit('showreview')">
              <span class="review_text" v-show="!showReview">REVIEW</span>
              <span class="return_text" v-show="showReview">RETURN</span>
            </button>
          </div>
          <div class="back" v-show="!showReview">
            <button type="primary" @click="$emit('doback', 'back')">
              BACK
            </button>
          </div>
          <div class="next" v-show="!showReview">
            <button type="primary" @click="$emit('donext', 'next')">
              NEXT
            </button>
          </div>
        </div>
        <div class="time">
          <button @click="timeShow = !timeShow">
            <b v-show="timeShow">Hide</b>
            <b v-show="!timeShow">Show</b>
          </button>
          <button type="danger">
            <span class="time_show" v-show="timeShow">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
            <span class="time_hide" v-show="!timeShow">
              <strong>Time</strong>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="header-default">
      <div class="col-sm-3">
        <a class="navbar-brand" href="/">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-3">
        Passage <b>{{ question_detail.chapter_order }}</b
        >, Question <b>{{ question_detail.question_order }} </b>
      </div>
      <div class="col-sm-6 control">
        <div class="review">
          <el-button type="primary" @click="$emit('showreview')">
            <span class="review_text" v-show="!showReview">REVIEW</span>
            <span class="return_text" v-show="showReview">RETURN</span>
          </el-button>
        </div>
        <div class="back" v-show="!showReview">
          <el-button type="primary" @click="$emit('doback', 'back')">
            BACK
          </el-button>
        </div>
        <div class="next" v-show="!showReview">
          <el-button type="primary" @click="$emit('donext', 'next')">
            NEXT
          </el-button>
        </div>
        <div class="time">
          <el-button @click="timeShow = !timeShow">
            <b v-show="timeShow">Hide</b>
            <b v-show="!timeShow">Show</b>
          </el-button>
          <el-button type="danger">
            <span class="time_show" v-show="timeShow">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
            <span class="time_hide" v-show="!timeShow">
              <strong>Time</strong>
            </span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showReview", "time", "question_detail", "mode"],
  methods: {},
  data() {
    return {
      timeShow: true
    };
  }
};
</script>

<style scoped>
.header-strict .control {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-strict .control-button {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header-strict .control .time {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.header-default .control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
