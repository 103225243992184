<template>
  <div id="QuestionDefault" class="col-xs-12 col-sm-12 col-md-12 cover">
    <div class="intro_screen question cover">
      <div class="title_text directions-style">
        {{ question_detail.question }}
      </div>
      <hr />
      <div class="row article">
        <div class="col-xs-12 col-md-6 cover article-text">
          <p class="article-title text-center"><b>Question</b></p>
          <p
            class="writing-article"
            v-html="question_detail.content.article"
          ></p>
        </div>
        <div class="col-xs-12 col-md-6 cover article-answer">
          <el-input
            type="textarea"
            style="width:100%;height: 100%"
            placeholder="Please type your response here"
            v-model="theAnswer"
          >
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props:["question_detail", "answer", "prepare_time", "answer_time"],
  created(){},
  computed:{},
  data() {
    return {
      showBar: false,
      theAnswer:this.answer,
      totalText: ""
    };
  },
  watch: {
    answer(){
      this.theAnswer = this.answer;
    },
    theAnswer() {
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      let answers = [];
      answers.push(this.theAnswer);
      this.$parent.setAnswers(answers);
    }
  }
};
</script>

<style scoped>
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
}
.time-container {
  margin: 0 auto;
}
#QuestionDefault >>> .el-textarea textarea {
  height: 100%;
}
.article-text {
  padding-right: 20px;
}
</style>
