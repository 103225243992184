<template>
  <div class="listening-passage col-xs-12 col-sm-12 col-md-12">
    <div class="intro_screen question cover" style="opacity: 1;">
      <p class="q_count text-center">
        Question<b> {{ question_detail.question_order }} </b>
      </p>
      <audio :src="question_detail.content.question_audio" autoplay></audio>
      <h3 class="q_title" v-html="question_detail.question"></h3>
      <el-checkbox-group v-model="theAnswers">
        <el-checkbox
          class="option"
          v-for="option in question_detail.options"
          :label="option.id"
          :key="option.id"
        >
          {{ option.title }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import _ from "lodash";
export default {
  props:["question_detail", "answers"],
  computed:{},
  data() {
    return {
      theAnswers: _.cloneDeep(this.answers)
    };
  },
  mounted(){
    this.getAnswer();
  },
  watch: {
    answers(value){
      this.theAnswers = _.cloneDeep(value);
    },
    theAnswers(){
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      if (this.theAnswers.length > this.question_detail.content.checkbox_count) {
        this.theAnswers.pop();
        // this.theAnswers = [];
      }
      let answers = _.cloneDeep(this.theAnswers);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b){
      return a - b;
    }
  }
};
</script>

<style scoped>
.cover {
  height: 100%;
}
.option {
  display: block;
  margin: 40px 0;
  line-height: 22px;
  text-align: left;
}
.option >>> .el-checkbox__label {
  font-size: 16px;
}
.option >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.option >>> .el-checkbox__inner::after {
  height: 8px;
  width: 4px;
  left: 6px;
  top: 3px;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
}
</style>
