<template>
  <div id="QuestionClick" class="col-xs-12 col-sm-6 col-md-6 cover">
    <div class="intro_screen question cover" style="opacity: 1;">
      <div class="reading_default">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>
        <h3 class="q_title" v-html="question_detail.question"></h3>
        <p class="sentence">{{ question_detail.content.click_option }}</p>
        <div class="options">
          <div
            class="radio"
            v-for="option in question_detail.options"
            :key="option.id"
          >
            <div>
              <input
                class="magic-radio"
                :value="option.id"
                v-model="theAnswer"
                type="radio"
                :id="option.id"
              />
              <label :for="option.id">{{ option.title }}</label>
            </div>
          </div>
        </div>
        <h5>The corresponding paragraph number is highlighted.</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "question-click",
  props: ["question_detail", "answers", "insertOption"],
  computed: {},
  data() {
    //不能直接修改answer
    return {
      theAnswer: this.answers[0]
    };
  },
  mounted() {
    this.getAnswer();
  },
  watch: {
    //监听answer进行改变
    answers(value) {
      this.theAnswer = value[0];
    },
    //你的答案
    theAnswer() {
      this.getAnswer();
    },
    insertOption(val) {
      this.question_detail.options.forEach(option => {
        if (option.title === val) {
          this.theAnswer = option.id;
        }
      });
    }
  },
  methods: {
    getAnswer() {
      let answers = [];
      answers.push(this.theAnswer);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
      this.question_detail.options.forEach(option => {
        if (option.id === this.theAnswer) {
          this.$emit("insertPassage", option.title);
        }
      });
    },
    cmp(a, b) {
      return a - b;
    }
  }
};
</script>

<style scoped>
.sentence {
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 10px;
  margin: 20px 5px;
}
</style>
