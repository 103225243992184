<template>
  <div>
    <div class="intro_screen cover">
      <div class="col-md-12">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>
        <div class="init_title text">
          <p class="title-p">
            Directions: An introductory sentence for a brief summary of the passage is provided below. Complete the summary by selecting the THREE answer choices that express the most important ideas in the passage. Some sentences do not belong in the summary because they express ideas that are not presented in the passage or are minor ideas in the passage. This question is worth 2 points.
          </p>
          <el-alert
            type="success"
            center
            :closable="false"
          >
            <h4>{{ getQuestion(question_detail.question) }}</h4>
          </el-alert>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <draggable
              class="list-group row"
              :list="myAnswers"
              group="people"
              @change="getAnswer"
            >
              <div
                class="list-item"
                v-for="element in myAnswers"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
            <h5 class="text-center">Answer Choices</h5>
          </div>
          <div class="col-sm-12">
            <draggable class="list-group row" :list="options" group="people">
              <div
                @click="alert"
                class="list-item"
                v-for="element in options"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  components: {
    draggable
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: []
    };
  },
  mounted() {
    this.getAnswer();
  },
  watch: {
    answers() {
      let options = [];
      let myAnswers = [];
      this.question_detail.options.forEach(option => {
        let index = this.answers.indexOf(option.id);
        if (index > -1) {
          myAnswers.push(option);
        } else {
          options.push(option);
        }
      });
      this.options = options;
      this.myAnswers = myAnswers;
      this.getAnswer();
    }
  },
  methods: {
    // setAnswers() {
    //   console.log(this.answers);
    // },
    alert() {
      this.$message({
        message:
          "Please click and DRAG your answer choices into the space above.",
        type: "warning"
      });
    },
    getQuestion(val){
      let text = val.replace(/Directions: An introductory sentence for a brief summary of the passage is provided below. Complete the summary by selecting the THREE answer choices that express the most important ideas in the passage. Some sentences do not belong in the summary because they express ideas that are not presented in the passage or are minor ideas in the passage. This question is worth 2 points./, "");
      return text;
    },
    getAnswer() {
      if (this.myAnswers.length > 3) {
        let item = this.myAnswers.pop();
        this.options.push(item);
      }
      let answers = [];
      this.myAnswers.forEach(option => {
        answers.push(option.id);
      });
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b) {
      return a - b;
    }
  }
};
</script>

<style scoped>
.list-group {
  width: 100%;
  min-height: 300px;
  /* max-height: 400px; */
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px 20px;
}
.list-item {
  min-height: 80px;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px 0;
}
</style>
