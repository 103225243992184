<template>
  <div style="width: 100%;height: 100%">
    <div v-if="mode == '1'" class="mylogo">
      <div class="col-sm-3">
        <a class="navbar-brand" href="/">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-3">
        <b class="test_name"> Speaking (Task {{ question_detail.order }})</b>
      </div>
      <div class="col-sm-6 control">
        <el-dropdown size="small" @command="changeSpeed">
          <span class="audio-speed">
            Speed: <b>{{ speed }} X</b>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in multipleArray"
              :key="index"
              :command="item"
            >
              {{ item }} X
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-popover placement="bottom" width="100" trigger="hover">
          <div class="block">
            <span class="demonstration">Vol</span>
            <el-slider v-model="vol"></el-slider>
          </div>
          <el-button slot="reference">Volume</el-button>
        </el-popover>
        <button @click="setPage" type="primary">
          Continue
        </button>
      </div>
    </div>
    <div v-else class="header-default">
      <div class="col-sm-6">
        <b class="test_name">Speaking (Task {{ question_detail.order }})</b>
      </div>
      <div class="col-sm-6 control">
        <el-dropdown size="small" @command="changeSpeed">
          <span class="audio-speed">
            Speed: <b>{{ speed }} X</b>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in multipleArray"
              :key="index"
              :command="item"
            >
              {{ item }} X
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-popover placement="bottom" width="100" trigger="hover">
          <div class="block">
            <span class="demonstration">Vol</span>
            <el-slider v-model="vol"></el-slider>
          </div>
          <el-button slot="reference" type="primary">Volume</el-button>
        </el-popover>
        <el-button @click="setPage" type="primary">
          Continue
        </el-button>
      </div>
    </div>

    <div class="listening-passage row mar">
      <div class="intro_screen cover text-center">
        <img class="style_change" :src="imgUrl" />
        <audio
          id="listening_passage"
          ref="audioPlayer"
          :src="question_detail.content.talking_audio"
          @canplay="play"
        ></audio>
        <h5>After the audio is done, please click "Continue."</h5>
        <div class="time-container col-md-6 col-md-offset-3">
          <div id="bar" style="width:0%;"></div>
        </div>
        <p class="col-md-12" id="total" style="color: #ccc"></p>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      timer: {},
      multipleArray: [0.8, 1, 1.2, 1.5, 2, 3, 4],
      speed: 1,
      vol: 100
    };
  },
  computed: {},
  watch: {
    vol() {
      this.changeVol();
    }
  },

  mounted() {},

  methods: {
    changeVol() {
      let audioPlayer = this.$refs.audioPlayer;
      audioPlayer.volume = this.vol / 100;
    },
    changeSpeed(command) {
      let audioPlayer = this.$refs.audioPlayer;
      audioPlayer.playbackRate = command;
      this.speed = command;
    },
    play() {
      this.showPlay = false;
      let passage_audio = document.getElementById("listening_passage");
      let vm = this;
      passage_audio.play();
      vm.run(Math.round(passage_audio.duration));
    },
    hiddenPassage() {
      this.$parent.hiddenPassage();
    },
    countDown(seconds) {
      let minute;
      let second;
      minute = Math.floor(seconds / 60);
      second = Math.floor(seconds) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    run(audio_time) {
      let vm = this;
      let timer;
      let bar = document.getElementById("bar");
      let total = document.getElementById("total");
      let width = 0;
      let time = vm.countDown(audio_time);
      let second = 0;
      let surplus_time;
      timer = window.setInterval(function() {
        second = second + 1 * vm.speed;
        surplus_time = vm.countDown(second);
        width += (100 * vm.speed) / audio_time;
        bar.style.width = width + "%";
        total.innerHTML = surplus_time + "/" + time;
        if (second >= audio_time) {
          bar.style.width = "100%";
          clearInterval(timer);
          return true;
        }
      }, 1000);
    },
    setPage() {
      clearInterval(this.timer);
      this.$parent.setPage();
    }
  }
};
</script>

<style scoped>
.intro_screen {
  width: 100%;
}
.mylogo {
  line-height: 80px;
}
.time-container {
  margin: 0 auto;
}
.mylogo .control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mylogo .control .el-dropdown {
  width: 100px;
}
.mylogo .audio-speed {
  display: inline-block;
  width: 100%;
}
.header-default .control .el-dropdown span {
  color: #42a16a;
}
.header-default .control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
