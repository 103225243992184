<template>
  <div>
    <passage-default
      :question_detail="question_detail"
      :mode="mode"
      v-if="showQuestion == false"
    >
    </passage-default>
    <div v-else>
      <div>
        <div
          v-if="
            question_detail.type === 'default' &&
              question_detail.content.repeat_audio == null
          "
        >
          <main-menu :mode="mode" @dook="getQuestion"></main-menu>
          <question-default
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          ></question-default>
        </div>
        <div v-if="question_detail.type === 'checkbox'">
          <main-menu :mode="mode" @dook="getQuestion"></main-menu>
          <question-checkbox
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          ></question-checkbox>
        </div>
        <div v-if="question_detail.type === 'sort'">
          <main-menu :mode="mode" @dook="getQuestion"></main-menu>
          <question-sort
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          ></question-sort>
        </div>
        <div v-if="question_detail.type === 'multiple_choice'">
          <main-menu :mode="mode" @dook="getQuestion"></main-menu>
          <question-multiple-choice
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          ></question-multiple-choice>
        </div>
        <div
          v-if="
            question_detail.type === 'default' &&
              question_detail.content.repeat_audio != null
          "
        >
          <question-repeat
            v-if="page === 1"
            :mode="mode"
            :question_detail="question_detail"
          >
          </question-repeat>
          <div v-if="page === 2">
            <main-menu :mode="mode" @dook="getQuestion"></main-menu>
            <question-default
              :question_detail="question_detail"
              @setAnswers="setAnswers"
              :answers="answers"
            ></question-default>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import MainMenu from "./components/MainMenu";
import PassageDefault from "./components/PassageDefault";
import QuestionDefault from "./components/QuestionDefault";
import QuestionCheckbox from "./components/QuestionCheckbox";
import QuestionSort from "./components/QuestionSort";
import QuestionRepeat from "./components/QuestionRepeat";
import QuestionMultipleChoice from "./components/QuestionMultipleChoice";
// import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Listening - Ivy-Way Academy"
    };
  },

  components: {
    MainMenu,
    PassageDefault,
    QuestionDefault,
    QuestionCheckbox,
    QuestionRepeat,
    QuestionSort,
    QuestionMultipleChoice
  },

  mixins: [],

  props: ["question_detail", "answers", "mode"],
  data() {
    return {
      showQuestion: false,
      page: 1
    };
  },
  computed: {},
  watch: {
    question_detail() {
      if (this.question_detail.question_order == 1) {
        this.showQuestion = false;
      } else {
        this.showQuestion = true;
      }
    }
  },

  mounted() {},

  methods: {
    hiddenPassage() {
      this.showQuestion = true;
    },
    getQuestion(value) {
      //判断前进还是后退；
      this.$parent.getQuestion(value, 0);
      this.page = 1;
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    setPage() {
      this.page++;
    }
  }
};
</script>

<style scoped></style>
