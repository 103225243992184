<template>
  <div>
    <div class="intro_screen cover">
      <div class="col-md-12">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>
        <div class="init_title text">
          <h3 class="q_title">
            {{ question_detail.question }}
          </h3>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h5 class="text-center">Options</h5>
            <draggable class="list-group" :list="options" group="people">
              <div
                class="list-item"
                v-for="element in options"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
          <div class="col-sm-6">
            <h5 class="text-center">Answer Choices</h5>
            <draggable
              class="list-group"
              :list="myAnswers"
              group="people"
              @change="getAnswer"
            >
              <div
                class="list-item"
                v-for="element in myAnswers"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  components: {
    draggable
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: []
    };
  },
  mounted() {
    this.getAnswer();
  },
  watch: {
    answers() {
      let options = [];
      let myAnswers = [];
      this.question_detail.options.forEach(option => {
        let index = this.answers.indexOf(option.id);
        if (index > -1) {
          myAnswers.push(option);
        } else {
          options.push(option);
        }
      });
      this.options = options;
      this.myAnswers = myAnswers;
      this.getAnswer();
    }
  },
  methods: {
    getAnswer() {
      let answers = [];
      this.myAnswers.forEach(option => {
        answers.push(option.id);
      });
      this.$parent.setAnswers(answers);
    }
  }
};
</script>

<style scoped>
.list-group {
  min-height: 100px;
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.list-item {
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px;
}
</style>
