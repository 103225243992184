<template>
  <div class="reading">
    <test-introduction
      v-if="showIntroduction"
      :mode="mode"
      @showintroductionclick="testStart"
    ></test-introduction>
    <div class="content cover" style="width:100%" v-else>
      <main-menu
        :showReview="showReview"
        :time="time"
        :question_detail="question_detail"
        :mode="mode"
        @donext="getQuestion"
        @doback="getQuestion"
        @showreview="showReview = !showReview"
      ></main-menu>

      <div v-show="!showReview">
        <!--非拖拽题目显示的页面-->
        <div
          class="single row mar"
          v-if="
            question_detail.type !== 'multiple' &&
              question_detail.type !== 'double'
          "
        >
          <question-default
            v-if="question_detail.type === 'default'"
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          ></question-default>
          <question-checkbox
            v-if="question_detail.type === 'checkbox'"
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          >
          </question-checkbox>
          <question-click
            v-if="question_detail.type === 'click'"
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @insertPassage="insertPassage"
            :insertOption="insertOption"
            :answers="answers"
          ></question-click>
          <passage-default
            :question_detail="question_detail"
            :mode="mode"
            @insertAnswer="insertAnswer"
            :insertTitle="insertTitle"
          ></passage-default>
        </div>
        <!--拖拽排序题目显示的页面-->
        <div class="multiple" v-else>
          <question-multiple
            v-if="question_detail.type === 'multiple'"
            :question_detail="question_detail"
            :answers="answers"
          ></question-multiple>
          <question-double
            v-if="question_detail.type === 'double'"
            :question_detail="question_detail"
            :answers="answers"
          ></question-double>
        </div>
      </div>
      <review-question
        v-if="showReview"
        :texts="filterReading(questions)"
        :allAnswers="allAnswers"
      ></review-question>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import TestIntroduction from "./components/TestIntroduction";
import MainMenu from "./components/MainMenu";
import QuestionDefault from "./components/QuestionDefault";
import QuestionClick from "./components/QuestionClick";
import PassageDefault from "./components/PassageDefault";
import QuestionMultiple from "./components/QuestionMultiple";
import QuestionDouble from "./components/QuestionDouble";
import ReviewQuestion from "./components/ReviewQuestion";
import QuestionCheckbox from "./components/QuestionCheckbox";

export default {
  metaInfo() {
    return {
      title: "TOEFL - Reading"
    };
  },

  components: {
    TestIntroduction,
    MainMenu,
    QuestionDefault,
    QuestionClick,
    QuestionMultiple,
    QuestionDouble,
    PassageDefault,
    ReviewQuestion,
    QuestionCheckbox
  },

  mixins: [],

  props: ["questions", "question_detail", "allAnswers", "answers", "mode"],
  data() {
    return {
      showReview: false,
      showIntroduction: true,
      user_exam: {},
      question_count: 0,
      time: {
        minute: "60",
        second: "00"
      },
      insertOption: "",
      insertTitle: ""
    };
  },
  computed: {
    progress_remaining() {
      return parseInt(this.time.minute) * 60 + parseInt(this.time.second);
    }
  },
  watch: {},
  mounted() {},
  methods: {
    filterReading(questions) {
      return _.filter(questions, ["skill_name", "reading"]);
    },
    //获取数据
    getQuestion(value) {
      //判断前进还是后退；
      this.$parent.getQuestion(value, this.progress_remaining);
    },
    testStart() {
      // let question = this.questions[0]["id"];
      this.showIntroduction = false;
      this.countDown(3600, 1);
    },
    countDown(seconds_remaining, test_id) {
      setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;

        this.time.minute = minute;
        this.time.second = second;

        seconds_remaining--;
        if (seconds_remaining == 0) {
          // location.href = "listening.php?test_id=" + test_id + "&id=" + id;
        }
      }, 1000);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    selectQuestion(question_index) {
      this.showReview = false;
      this.$parent.skipQuestion(question_index);
    },
    insertAnswer(data) {
      this.insertOption = data;
    },
    insertPassage(data) {
      console.log(data);
      this.insertTitle = data;
    }
  }
};
</script>
