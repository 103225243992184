<template>
  <div style="width: 100%;height: 100%">
    <audio
      id="beep_prepare"
      src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/audio/speaking_beep_prepare.mp3"
    ></audio>
    <audio
      id="beep_answer"
      src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/audio/speaking_beep_answer.mp3"
    ></audio>
    <audio
      id="speaking_title"
      :src="question_detail.content.title_audio"
      @canplay="play()"
    ></audio>
    <div :class="mode == '1' ? 'mylogo' : 'header-default'">
      <div class="col-sm-4">
        <a class="navbar-brand" href="/">
          <img
            v-if="mode == '1'"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
            class="logo logo_image"
          />
          <img
            v-else
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-4">
        <b class="test_name"> Speaking (Task {{ question_detail.order }}) </b>
      </div>
      <div class="col-sm-4" v-show="showOk">
        <el-button type="primary" @click="postAnswer">
          OK
        </el-button>
      </div>
    </div>
    <div class="listening-passage row mar">
      <div class="intro_screen question" style="opacity: 1;">
        <h4 class="text-center">Question</h4>
        <div class="title_text">{{ question_detail.question }}</div>
        <hr />
        <div v-show="!showBar">
          <h4 class="text-center">
            <i class="fa fa-headphones" aria-hidden="true"></i>
            Preparation Time Remaining: <b>{{ prepare_time }}</b> Seconds
          </h4>
          <h4 class="text-center">
            <i class="fa fa-headphones" aria-hidden="true"></i>
            Response Time Remaining: <b>{{ answer_time }}</b> Seconds
          </h4>
        </div>
        <div v-show="showBar">
          <h4 class="col-md-12 text-center record-total" id="record-total-1">
            {{ totalText }}
          </h4>
          <div class="time-container record-time col-md-6 col-md-offset-3">
            <div id="record-bar-1" class="bar" style="width:0%;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props:["question_detail", "answer", "prepare_time", "answer_time", "mode"],
  created(){},
  computed:{},
  data() {
    return {
      showOk:false,
      showBar: false,
      theAnswer:this.answer,
      totalText: "",
      timer:{}
    };
  },
  watch: {},
  methods: {
    play(){
      let vm = this;
      let speaking_title = document.getElementById("speaking_title");
      let beep_prepare = document.getElementById("beep_prepare");
      let beep_answer = document.getElementById("beep_answer");
      speaking_title.play();
      speaking_title.onended = function(){
        beep_prepare.play();
      };
      beep_prepare.onended = function(){
        // $(".writing-test h3").hide();
        let prepareTime = vm.prepare_time;
        vm.showBar = true;
        // prepareTime = 5;
        vm.recordRun(prepareTime,"record-bar-1","record-total-1","prepare");
      };
      beep_answer.onended = function(){
        let answerTime = vm.answer_time;
        vm.recordRun(answerTime,"record-bar-1","record-total-1","answer");
        vm.$parent.readyOriginal();
      };
    },
    recordRun(audio_time,IdBar,total,type){
      let vm = this;
      var bar = document.getElementById(IdBar);
      var width = 0;
      var second = audio_time;
      if(type == "prepare"){
        type = "Prepare";
      }else if(type == "answer"){
        vm.showBar = true;
        type = "Answer";
      };
      vm.totalText = type + ": " + second +"s ";
      vm.timer = window.setInterval(function(){
        if(bar.style.width == "100%"){
          vm.totalText = "";
          clearInterval(vm.timer);
        }
        second--;
        width += 100/audio_time;
        bar.style.width = width + "%";
        vm.totalText = type + ": " + second +"s ";
        if(Math.ceil(width) >= 100){
          clearInterval(vm.timer);
          if(type == "Prepare"){
            let beep_answer = document.getElementById("beep_answer");
            beep_answer.play();
            vm.showBar = false;
            bar.style.width = 0;
          }else if(type == "Answer"){
            vm.showOk = true;
            vm.$parent.readyOriginal();
          };
          return true;
        }
      },1000);
    },
    postAnswer(){
      clearInterval(this.timer);
      this.$parent.getQuestion("next");
    }
  }
};
</script>

<style scoped>
.cover {
  height: 100%;
}
.time-container {
  margin: 0 auto;
}
.intro_screen {
  width: 100%;
}
</style>
