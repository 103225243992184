<template>
  <div style="width: 100%;height: 100%">
    <div :class="mode == '1' ? 'mylogo' : 'header-default'">
      <div class="col-sm-4">
        <a class="navbar-brand" href="/">
          <img
            v-if="mode == '1'"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
            class="logo logo_image"
          />
          <img
            v-else
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-4">
        <b class="test_name">
          Writing Task ({{ question_detail.order }})
        </b>
      </div>
      <div class="col-sm-4">
        <el-button @click="setPage" type="primary">
          Continue
        </el-button>
      </div>
    </div>

    <div class="listening-passage row mar">
      <div
        class="intro_screen cover text-center"
        v-html="question_detail.content.article"
      ></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    setPage() {
      this.$parent.setPage();
    }
  }
};
</script>

<style scoped>
.intro_screen {
  width: 100%;
}
.mylogo {
  line-height: 80px;
}
.time-container {
  margin: 0 auto;
}
.intro_screen >>> .article {
  height: 100%;
  overflow: auto;
}
</style>
