<template>
  <div>
    <div v-if="mode == '1'" class="mylogo">
      <div class="col-sm-4">
        <a class="navbar-brand" href="/">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo-white.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-4">
        <button type="primary" @click="$emit('dook', 'next')">
          Next
        </button>
      </div>
    </div>
    <div v-else class="header-default">
      <div class="col-sm-4">
        <a class="navbar-brand" href="/">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
            class="logo logo_image"
          />
        </a>
      </div>
      <div class="col-sm-4">
        <el-button type="primary" @click="$emit('dook', 'next')">
          Next
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  props: ["answers", "mode"],
  watch: {
    answers() {
      this.showAnswer = false;
    }
  },
  methods: {},
  data() {
    return {
      showAnswer: false
    };
  }
};
</script>

<style scoped>
.your_ansers {
  line-height: 80px;
}
</style>
